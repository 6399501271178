import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { trimValues } from '@/application/utils/trim.js'
import { sendAddPassword } from '@/application/services/auth.js'
import { getLocalizedServerError } from '@/application/utils/localization'
import { extractTokensFromUrl } from '@/application/utils/tokens'

const { token, secret } = extractTokensFromUrl()
const headers = { Authorization: token, 'Loop-Secret-Key': secret }

export function useAddPasswordForm() {
  const i18n = useI18n()
  const router = useRouter()
  const isDisabled = ref(false)
  const errorMessage = ref('')
  const addPasswordFormSchema = computed(() => [
    {
      label: i18n.t('forms.addPassword.password'),
      name: 'password',
      as: 'input',
      type: 'password',
      rules: 'required|min:8|alpha_num',
      cols: 'col-12',
      inputClass: 'form-control',
    },
    {
      label: i18n.t('forms.addPassword.confirmPassword'),
      name: 'confirm_password',
      as: 'input',
      type: 'password',
      rules: 'required|min:8|alpha_num',
      cols: 'col-12',
      inputClass: 'form-control',
    },
  ])

  const addPasswordSubmitButton = computed(() => ({
    wrapper: 'justify-content-center',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t('forms.addPassword.submit'),
  }))

  const submitAddPassword = (data) => {
    isDisabled.value = true
    const formatted = trimValues(data)
    sendAddPassword(formatted, headers)
      .then(() => {
        isDisabled.value = false
        router.push({
          name: 'dashboard',
        })
      })
      .catch(err => {
        errorMessage.value = getLocalizedServerError(i18n, 'forms.signUp.errors.', err.response)
        isDisabled.value = false
      })
  }

  return {
    addPasswordFormSchema,
    submitAddPassword,
    addPasswordSubmitButton,
    errorMessage,
    isDisabled,
  }
}
