<template>
  <h3 class="mb-4 text-center">{{ $t("forms.addPassword.title") }}</h3>
  <div class="form-group mb-3">
    <p class="mb-3 text-center">{{ $t("forms.addPassword.description") }}</p>
    <DynamicForm
      :schema="addPasswordFormSchema"
      :button-data="addPasswordSubmitButton"
      :is-disabled="addPasswordDisabled"
      form-class="justify-content-center"
      @submit="submitAddPassword"
    >
      <template v-slot:error>
        <div
          class="server-error-message content mt-3"
          v-if="addPasswordErrorMessage.length"
        >
          {{ addPasswordErrorMessage }}
          <button
            @click.prevent="addPasswordErrorMessage = ''"
            class="feather icon-x button"
          ></button>
        </div>
      </template>
    </DynamicForm>
  </div>
</template>

<script>
import DynamicForm from './DynamicForm.vue'
import { useAddPasswordForm } from '@/application/composables/addPasswordForm.js'

export default {
  name: 'AddPasswordForm',
  components: {
    DynamicForm,
  },
  setup() {
    const {
      addPasswordFormSchema,
      submitAddPassword,
      addPasswordSubmitButton,
      isDisabled: addPasswordDisabled,
      errorMessage: addPasswordErrorMessage,
    } = useAddPasswordForm()

    return {
      addPasswordFormSchema,
      submitAddPassword,
      addPasswordSubmitButton,
      addPasswordDisabled,
      addPasswordErrorMessage,
    }
  },
}
</script>
